import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useColorMode } from "theme-ui";

import Icons from "@icons";

const ScrollToTop = () => {
  const [showScroll, setShowScroll] = useState(false);
  const [colorMode] = useColorMode();

  const isDark = colorMode === "dark";

  useEffect(() => {
    const checkScrollTop = () => {
      if (!showScroll && window.pageYOffset > 400) {
        setShowScroll(true);
      } else if (showScroll && window.pageYOffset <= 400) {
        setShowScroll(false);
      }
    };

    window.addEventListener("scroll", checkScrollTop);
  });

  const scrollTop = () => {
    if (typeof window == `undefined`) {
      return null;
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <ScrollToTopContainer isDark={isDark}>
      <button
        className="scrollTop"
        onClick={scrollTop}
        style={{ height: 40, display: showScroll ? "flex" : "none" }}
      >
        <Icons.ChevronUp fill={isDark ? "#7578e1" : "#fff"} />
      </button>
    </ScrollToTopContainer>
  );
};

export default ScrollToTop;

const ScrollToTopContainer = styled.div<{ isDark: boolean }>`
  .scrollTop {
    position: fixed;
    width: 100%;
    max-width: 50px;
    height: 30px;
    bottom: 20px;
    z-index: 100;
    cursor: pointer;
    transition: opacity 0.4s;
    opacity: 1;

    justify-content: center;
    align-items: center;

    background: ${(p) => (p.isDark ? "#a0a0a2" : p.theme.colors.grey)};
    box-shadow: rgba(44, 62, 80, 0.23) 0px 0.4em 0.8em -2px,
      rgba(102, 119, 136, 0.2) 0px 1px 2px;
    right: 0;

    transition: background 0.4s;
  }

  .scrollTop:hover {
    background: ${(p) => (p.isDark ? "#fff" : "#000")};
  }
`;
